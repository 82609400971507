var columns = [{
  title: '新闻ID',
  dataIndex: 'id',
  key: 'id',
  width: '7%',
  scopedSlots: {
    customRender: 'newsid'
  }
}, {
  title: '新闻标题',
  dataIndex: 'title',
  key: 'title',
  width: '22%',
  scopedSlots: {
    customRender: 'title'
  }
}, {
  title: '新闻链接',
  dataIndex: 'url',
  key: 'url',
  width: '22%',
  scopedSlots: {
    customRender: 'url'
  }
}, {
  title: '侧边推荐位排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '15%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: 'Feed强推排序',
  dataIndex: 'recommendedsort',
  key: 'recommendedsort',
  width: '15%',
  scopedSlots: {
    customRender: 'recommendedsort'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  //   fixed: "right",
  width: '19%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };